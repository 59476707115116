/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-04-06 09:51:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-22 15:03:33
 */
export const columns = [

{
    title: '部门',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'left',
    ellipsis:true
},
{
  title: '编号',
  dataIndex: 'code',
  key: 'code',
  /*width:150,*/
  align:'left',
  ellipsis:true
},
{
  title: '部门主管',
  dataIndex: 'leaderName',
  key: 'leaderName',
  /*width:150,*/
  align:'left',
  ellipsis:true
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagEnable'}
},
]
